// eslint-disable-next-line import/prefer-default-export
export enum ErrorReporterDomain {
	SPORTSBOOK = 'sportsbook',
	CASINO = 'casino',
	LIVE_CASINO = 'live-casino',
	WESPIN = 'wespin',
	AUTHENTICATION = 'authentication',
	ACCOUNT = 'account',
	PAYMENT = 'payment',
	SPLASHTECH = 'splashtech',
	THUNDERBITE = 'thunderbite',
	INSPIRED = 'inspired',
	UNKNOWN = 'unknown'
}
