import * as Sentry from '@sentry/react';
import { CaptureContext, ScopeContext } from '@sentry/types';

export type ErrorOptions = {
	// Domain of where the error occurred
	domain: string;
	// Additional tags
	tags?: ScopeContext['tags'];
	// Any additional unstructured data that may help understanding the error
	extra?: ScopeContext['extra'];
	// Severity level of the error
	severityLevel?: Sentry.SeverityLevel;
};

function getCaptureContext({ domain, tags, severityLevel, extra }: ErrorOptions): CaptureContext {
	return {
		tags: {
			domain,
			...(tags || {})
		},
		level: severityLevel,
		extra
	};
}

/**
 * Reports a message to Sentry
 * @param message - The message to report
 * @param options - Configuration options for the reported message
 * @param options.domain - Domain of where the error occurred
 * @param [options.tags] - Tags that are commonly used across all errors. If specific data is needed specifically to this error or if you are unsure, use extra instead.
 * @param [options.extra] - Any additional unstructured data that may help understanding the message. Use for specific error details.
 * @param [options.severityLevel] - Severity level of the message
 */
export function reportMessage(message: string, options: ErrorOptions) {
	Sentry.captureMessage(message, {
		...getCaptureContext(options)
	});
}

/**
 * Reports an exception to Sentry
 * @param exception - The error object to report
 * @param options - Configuration options for the reported exception
 * @param options.domain - Domain of where the exception occurred
 * @param [options.tags] - Tags that are commonly used across all errors. If specific data is needed specifically to this error or if you are unsure, use extra instead.
 * @param [options.extra] - Any additional unstructured data that may help understanding the exception. Use for specific error details.
 * @param [options.severityLevel] - Severity level of the exception
 */
export function reportException(exception: Error, options: ErrorOptions) {
	Sentry.captureException(exception, {
		...getCaptureContext(options)
	});
}
